/* eslint-disable no-lonely-if */
/* eslint-disable no-script-url */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable react/no-array-index-key */
// libraries
import React, { useContext, useEffect, useState } from "react";
import moment from "moment";
import { isEmpty, isEqual } from "lodash";
import { makeStyles } from "@mui/styles";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { CircularProgress } from "@mui/material";
// apollo components
import Box from "apollo-react/components/Box";
import Button from "apollo-react/components/Button";
import Checkbox from "apollo-react/components/Checkbox";
import InfoIcon from "apollo-react-icons/Info";
import MenuItem from "apollo-react/components/MenuItem";
import Modal from "apollo-react/components/Modal/Modal";
import Loader from "apollo-react/components/Loader";
import Panel from "apollo-react/components/Panel";
import Paper from "apollo-react/components/Paper";
import PasswordInput from "apollo-react/components/PasswordInput";
import Pencil from "apollo-react-icons/Pencil";
import PlusIcon from "apollo-react-icons/Plus";
import Select from "apollo-react/components/Select";
import TextField from "apollo-react/components/TextField";
import Tooltip from "apollo-react/components/Tooltip";
import Typography from "apollo-react/components/Typography";
// components
import Header from "../../components/DataFlow/Header";
import LeftPanel from "../../components/Dataset/LeftPanel/LeftPanel";
import SaveChangesModal from "../../components/DataFlow/SaveChangesModal";
import { ReactComponent as DataPackageIcon } from "../../components/Icons/datapackage.svg";
// helpers
import dataPackageService from "../../services/dataPackageService";
import {
  getUserInfo,
  toast,
  validateFields,
  isSftp,
  validatePackageName,
  checkIfLocationIsS3,
  getDataFlowBreadCrumb,
} from "../../utils";
import { MessageContext } from "../../components/Providers/MessageProvider";
import { packageComprTypes, packageTypes } from "../../utils/constants";
import { saveDraftDataPackage } from "../../services/ApiServices";
import { updateDSStatus } from "../../store/actions/DataSetsAction";
import {
  getDataFlowDetail,
  setDraftEditMode,
  updateDSState,
} from "../../store/actions/DataFlowAction";
import {
  addDataPackage,
  addPackageBtnAction,
  getPackagePassword,
  getPackagesList,
  redirectToDataSet,
  selectDataPackage,
} from "../../store/actions/DataPackageAction";
import {
  Categories,
  Features,
  useStudyPermission,
} from "../../components/Common/usePermission";
import { encrypt } from "../../utils/encryptionHelper";
import { formComponentActive } from "../../store/actions/AlertActions";
import AlertBox from "../AlertBox/AlertBox";

// styles
import "./DataPackages.scss";

const useStyles = makeStyles(() => ({
  rightPanel: {
    maxWidth: "calc(100vw - 466px)",
    width: "calc(100vw - 464px)",
  },
  rightPanelExtended: {
    maxWidth: "calc(100vw - 42px)",
    width: "calc(100vw - 40px)",
  },
}));

const DataPackages = React.memo(() => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const history = useHistory();

  const userInfo = getUserInfo();

  const [isShowAlertBox, setShowAlertBox] = useState(false);
  const [showForm, setShowForm] = useState(false);
  const [configShow, setConfigShow] = useState(false);
  const [sodValue, setSodValue] = useState("");
  const [compression, setCompression] = useState("");
  const [namingConvention, setNamingConvention] = useState("");
  const [packagePassword, setPackagePassword] = useState("");
  const [sftpPath, setSftpPath] = useState("");
  const [notMatchedType, setNotMatchedType] = useState(false);
  const [isPanelOpen, setIsPanelOpen] = useState(true);
  const [addedPackage, setAddedPackage] = useState(false);
  const [shouldTriggerRedirect, setShouldTriggerRedirect] = useState(true);
  const [isAnyChange, setIsAnyChange] = useState(false);
  const [folderPathValidation, setFolderPathValidation] = useState(false);
  const [passwordUpdate, setPasswordUpdate] = useState(false);
  const [btnLoading, setBtnLoading] = useState(false);
  const [tabularSod, setTabularSod] = useState(false);
  const [disabled, setDisabled] = useState(false);
  const [refreshData, setRefreshData] = useState(false);
  const [isValidName, setIsValidName] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");

  const [showInput, setShowInput] = useState(false);
  const [fromCreate, setFromCreate] = useState(false);
  const [tabularVeeva, setTabularVeeva] = useState(false);

  const [isS3Location, setIsS3Location] = useState(false);

  // Save Change Master Flag
  const SAVE_CHANGE_MODAL_FLAG =
    process.env.REACT_APP_SAVE_CHANGE_MODAL_FLAG === "true" ? true : false;

  const { showSuccessMessage, showErrorMessage } = useContext(MessageContext);
  const isVeevaOrTabularSod = tabularSod || tabularVeeva;

  const packageData = useSelector((state) => state.dataPackage);
  const { dataFlowdetail, versionFreezed, isDraftEditMode, isPublishedFlow } =
    useSelector((state) => state.dataFlow);
  const { selectedCard, compressionType } = useSelector(
    (state) => state.dashboard
  );

  const { packageSODPassword, pageLoaders, selectedPackage } = packageData;

  const { prot_id: protId } = selectedCard;

  const { passwordFetchLoading } = pageLoaders;
  const { loctyp: locType, dataflowid: dfId } = dataFlowdetail;

  const { canUpdate: canUpdateDataFlow } = useStudyPermission(
    Categories.CONFIGURATION,
    Features.DATA_FLOW_CONFIGURATION,
    protId
  );

  const goToDataflow = () => {
    if (dfId) {
      history.push(`/dashboard/dataflow-management/${dfId}`);
    } else if (dataFlowdetail?.dataflowid) {
      history.push(
        `/dashboard/dataflow-management/${dataFlowdetail?.dataflowid}`
      );
    }
  };

  const breadCrumbItems = getDataFlowBreadCrumb({
    history,
    location: "DATAPACKAGE",
    dataflowId: null,
    dataFlowName: dataFlowdetail?.name,
    dataPackageName: packageData?.selectedPackage?.name,
    goToDataflow,
  });

  const packagePasswordData = async () => {
    await dispatch(
      getPackagePassword(
        packageData.selectedPackage?.dataflowid,
        packageData.selectedPackage?.datapackageid
      )
    );
    setPasswordUpdate(true);
  };

  const resetForm = () => {
    setConfigShow(false);
    setNamingConvention("");
    setPackagePassword("");
    setSftpPath("");
    setCompression("");
    setNotMatchedType(false);
    setFolderPathValidation(false);
    setErrorMessage("");
    setIsValidName(false);
  };

  const showConfig = (e, checked) => {
    setConfigShow(checked);
    if (checked) {
      dispatch(setDraftEditMode(true));
    }
  };

  const setInitialFormData = () => {
    if (isVeevaOrTabularSod) {
      setConfigShow(true);
      setCompression("ZIP");
      setNotMatchedType(false);
      if (tabularSod) {
        setSodValue(packageData?.selectedPackage?.sod_view_type || "Regular");
      }
    } else {
      setConfigShow(false);
      setDisabled(false);
      setCompression("");
    }
    setDisabled(isVeevaOrTabularSod || (locType && !isSftp(locType)));
  };

  useEffect(() => {
    setInitialFormData();

    // check if location type is S3
    const isLocationS3 = checkIfLocationIsS3(locType);
    setIsS3Location(isLocationS3);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [locType, isVeevaOrTabularSod]);

  useEffect(() => {
    setTabularSod(dataFlowdetail?.type === "TabularRaveSOD" || false);
    setTabularVeeva(dataFlowdetail?.type === "TabularVeeva" || false);
    if (
      dataFlowdetail?.type === "TabularRaveSOD" ||
      dataFlowdetail?.type === "TabularVeeva"
    ) {
      setShowForm(true);
    }
  }, [dataFlowdetail]);

  useEffect(() => {
    if (packageData.openAddPackage) {
      resetForm();
      setShowForm(true);
    }
    return () => {
      resetForm();
    };
  }, [packageData.openAddPackage]);

  useEffect(() => {
    if (!packageData.openAddPackage && packageData.selectedPackage) {
      setShowForm(true);
      if (!isEmpty(packageData?.selectedPackage)) {
        setConfigShow(!packageData?.selectedPackage?.nopackageconfig);
        setCompression(packageData.selectedPackage?.type);
        // if (!packageData?.selectedPackage?.nopackageconfig) {
        setNamingConvention(packageData?.selectedPackage?.name || "");
        // }
        setSodValue(packageData.selectedPackage?.sod_view_type);
        setSftpPath(packageData.selectedPackage?.path);
        if (
          packageData.selectedPackage?.password === "Yes" &&
          !packageSODPassword
        ) {
          packagePasswordData();
          if (packageSODPassword === "") {
            setConfigShow(false);
          } else if (packageSODPassword !== "" && !passwordUpdate) {
            setConfigShow(false);
          } else {
            setPackagePassword(packageSODPassword);
            setConfigShow(true);
          }
        } else {
          if (!isEmpty(packageData?.selectedPackage))
            setConfigShow(!packageData?.selectedPackage?.nopackageconfig);
          if (!packageSODPassword) {
            setPackagePassword("");
          } else setPackagePassword(packageSODPassword);
        }
      }
    } else {
      resetForm();
      setInitialFormData();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    selectedPackage,
    packageData.openAddPackage,
    packageData.selectedPackage,
    packageData.packageSODPassword,
    refreshData,
    isVeevaOrTabularSod,
  ]);

  useEffect(() => {
    setPackagePassword(packageSODPassword);
  }, [packageSODPassword]);

  useEffect(() => {
    if (!isSftp(dataFlowdetail.loctyp)) {
      setConfigShow(false);
    }
    return () => {
      dispatch(selectDataPackage({}));
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    const currentDataPackageData = {
      compression_type: compression,
      dataflow_id: dfId,
      naming_convention: namingConvention,
      package_password: packagePassword,
      sftp_path: sftpPath,
      study_id: selectedCard.prot_id,
      user_id: userInfo.userId,
      versionFreezed,
    };
    const storeDataPackageData = {
      compression_type: packageData?.selectedPackage?.type,
      dataflow_id: packageData?.selectedPackage?.dataflowid,
      naming_convention: packageData?.selectedPackage?.name,
      package_password: packageSODPassword,
      sftp_path: packageData?.selectedPackage?.path,
      study_id: selectedCard.prot_id,
      user_id: userInfo.userId,
      versionFreezed,
    };
    const initialValueUndefined = {
      compression_type: undefined,
      dataflow_id: dfId,
      naming_convention: undefined,
      package_password: "",
      sftp_path: undefined,
      study_id: selectedCard.prot_id,
      user_id: userInfo.userId,
      versionFreezed,
    };
    const initialValueBlank = {
      compression_type: "",
      dataflow_id: dfId,
      naming_convention: "",
      package_password: "",
      sftp_path: "",
      study_id: selectedCard.prot_id,
      user_id: userInfo.userId,
      versionFreezed,
    };
    setIsAnyChange(false);

    const notAnyChange =
      (!isEmpty(packageData?.selectedPackage) &&
        isEqual(currentDataPackageData, storeDataPackageData)) ||
      (isEmpty(packageData?.selectedPackage) &&
        (isEqual(currentDataPackageData, initialValueUndefined) ||
          isEqual(currentDataPackageData, initialValueBlank)));

    setIsAnyChange(!notAnyChange);
    return () => {
      setIsAnyChange(false);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    compression,
    namingConvention,
    packagePassword,
    sftpPath,
    selectedCard.prot_id,
    dfId,
    userInfo.userId,
    versionFreezed,
    configShow,
  ]);

  useEffect(() => {
    if (isPublishedFlow || !isDraftEditMode) {
      setShowInput(false);
    } else {
      setShowInput(true);
    }
  }, [isPublishedFlow, isDraftEditMode]);

  useEffect(() => {
    if (history.location?.state?.from === "create-dataflow") {
      setFromCreate(true);
      dispatch(setDraftEditMode(true));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // Set form to active set for alert box configuration
  useEffect(() => {
    if (isDraftEditMode) dispatch(formComponentActive());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isDraftEditMode]);

  const handleAddedSuccess = (
    message,
    isRedirect = true,
    closeModal = false
  ) => {
    if (message) showSuccessMessage(message);
    const dataflowID =
      dfId ||
      packageData.selectedPackage?.dataflowid ||
      dataFlowdetail?.dataflowid;
    if (isRedirect && isEmpty(packageData.selectedPackage)) {
      dispatch(addDataPackage(false));
      setAddedPackage(false);
      resetForm();
      setShowForm(false);
      history.push(`/dashboard/dataflow-management/${dataflowID}`);
    }
    if (!isRedirect && closeModal) {
      setAddedPackage(false);
    }
  };

  const handleSavePackageSuccess = async (result, updatedReq) => {
    const dataflowID =
      dfId ||
      packageData.selectedPackage?.dataflowid ||
      dataFlowdetail?.dataflowid;

    if (updatedReq) {
      handleAddedSuccess(result.message, false);
      if (configShow === false) {
        resetForm();
      }
      if (fromCreate && !isVeevaOrTabularSod) {
        // disable save changes modal on redirection
        setShouldTriggerRedirect(false);
        dispatch(
          redirectToDataSet(
            dataflowID,
            "",
            packageData.selectedPackage?.datapackageid,
            namingConvention || packageData.selectedPackage?.name,
            "",
            "",
            null,
            sftpPath || packageData?.selectedPackage?.path
          )
        );
        dispatch(updateDSState(true));
        dispatch(updateDSStatus(false));
        history.push("/dashboard/dataset/new");
      }
      // update selected data package in store
      if (result?.data?.datapackageid) {
        const singlePackageData =
          await dataPackageService.getDataPackageDetailsById(
            dataflowID,
            result?.data?.datapackageid
          );
        if (singlePackageData?.data?.[0]) {
          dispatch(selectDataPackage(singlePackageData?.data?.[0]));
        }
      }
    } else {
      // disable save changes modal on redirection
      setShouldTriggerRedirect(false);

      // get single package data and set in store
      if (result?.data?.datapackageid) {
        const singlePackageData =
          await dataPackageService.getDataPackageDetailsById(
            dataflowID,
            result?.data?.datapackageid
          );
        if (singlePackageData?.data?.[0]) {
          dispatch(selectDataPackage(singlePackageData?.data?.[0]));
          if (dataflowID) dispatch(getDataFlowDetail(dataflowID, false));
          history.push("/dashboard/data-packages");
        } else {
          if (!isVeevaOrTabularSod) {
            history.push(`/dashboard/dataflow-management/${dataflowID}`);
          }
        }
      } else {
        if (!isVeevaOrTabularSod) {
          history.push(`/dashboard/dataflow-management/${dataflowID}`);
        }
      }
    }
    dispatch(setDraftEditMode(false));
    await dispatch(getPackagesList(dataflowID, "", true, true));
  };

  // eslint-disable-next-line consistent-return
  const submitPackage = async () => {
    setShouldTriggerRedirect(false);
    if (configShow) {
      const validated = validateFields(namingConvention, compression);
      const packageNameValidation = validatePackageName(
        namingConvention,
        isS3Location
      );
      setNotMatchedType(!validated);

      setShouldTriggerRedirect(true);
      // check folder path field
      if (!sftpPath?.trim() && !isS3Location) {
        setFolderPathValidation(true);
      }
      if (typeof packageNameValidation === "string") {
        setIsValidName(true);
        setErrorMessage(packageNameValidation);
        setNotMatchedType(false);
      }
      if (
        !validated ||
        (!sftpPath?.trim() && !isS3Location) ||
        typeof packageNameValidation === "string"
      )
        return false;

      if (namingConvention === "" && compression) {
        toast("Please fill all fields to proceed", "error");
        return false;
      }
    }

    const updatedReq = !!packageData.selectedPackage?.datapackageid;
    // encrypt password before sending

    // check active status
    let activeState = 1;
    if (updatedReq && !isVeevaOrTabularSod) {
      activeState = packageData.selectedPackage?.active;
    }
    let reqBody = {
      compression_type: compression || null,
      naming_convention: namingConvention || "",
      package_password: encrypt(packagePassword),
      sftp_path: sftpPath || "",
      study_id: selectedCard.prot_id,
      dataflow_id:
        dfId ||
        packageData.selectedPackage?.dataflowid ||
        dataFlowdetail?.dataflowid,
      user_id: userInfo.userId,
      versionFreezed,
      nopackageconfig: configShow === false ? true : false,
      sod_view_type: sodValue || false,
      active: activeState,
    };
    if (updatedReq) {
      reqBody = {
        ...reqBody,
        package_id: packageData.selectedPackage?.datapackageid,
        sod_view_type: sodValue,
      };
      if (configShow === false) {
        reqBody = {
          ...reqBody,
          compression_type: null,
          naming_convention: "",
          package_password: "",
          sftp_path: "",
          nopackageconfig: true,
        };
      }
    }
    const result = await saveDraftDataPackage(reqBody);
    if (result.status === 1) {
      // handle logic for success
      await handleSavePackageSuccess(result, updatedReq);
    } else {
      showErrorMessage(
        result.message || result?.error?.join(", ") || "Something went wrong"
      );
    }
  };

  const handleClose = () => {
    setIsPanelOpen(false);
  };

  const handleOpen = () => {
    setIsPanelOpen(true);
  };

  const doCancel = () => {
    setShowAlertBox(false);
    const dataflowID =
      dfId ||
      packageData.selectedPackage?.dataflowid ||
      dataFlowdetail?.dataflowid;
    if (configShow && isEmpty(packageData.selectedPackage)) {
      if (isVeevaOrTabularSod) {
        setShouldTriggerRedirect(false);
        history.push(`/dashboard/dataflow-management/${dataflowID}`);
      } else {
        resetForm();
      }
    } else if (!configShow && isEmpty(packageData.selectedPackage)) {
      resetForm();
      dispatch(setDraftEditMode(false));
      handleAddedSuccess();
    } else if (configShow && !isEmpty(packageData.selectedPackage)) {
      dispatch(setDraftEditMode(false));
      if (packageData?.selectedPackage?.nopackageconfig) {
        resetForm();
      } else {
        setRefreshData(!refreshData);
        setNotMatchedType(false);
      }
    } else if (!configShow && !isEmpty(packageData.selectedPackage)) {
      dispatch(setDraftEditMode(false));
    } else {
      resetForm();
      dispatch(setDraftEditMode(false));
      handleAddedSuccess();
    }
  };
  const handleCancelBtn = () => {
    if (
      SAVE_CHANGE_MODAL_FLAG &&
      isAnyChange &&
      dataFlowdetail?.type === "TabularVeeva"
    ) {
      setShowAlertBox(true);
    } else doCancel();
  };

  const handlePackageName = (value, pNotMatchedType = false) => {
    setErrorMessage("");
    setIsValidName(false);
    const nameMsg = validatePackageName(value, isS3Location);
    if (pNotMatchedType || notMatchedType) {
      if (isVeevaOrTabularSod) {
        setErrorMessage(
          "File extension must match package compression type e.g.zip"
        );
      } else {
        setErrorMessage(
          "File extension must match package compression type e.g. 7z, zip, rar, or xpt"
        );
      }
      setIsValidName(false);
      if (nameMsg === true) return;
    }
    if (nameMsg === true) {
      setIsValidName(false);
      setErrorMessage("");
      setNotMatchedType(false);
    } else if (nameMsg) {
      setIsValidName(true);
      setErrorMessage(nameMsg);
      setNotMatchedType(false);
    }
  };

  const lastModifieddate =
    packageData.selectedPackage?.updt_tm &&
    moment(packageData.selectedPackage?.updt_tm).format("DD-MMM-YYYY hh:mm A");

  // validate fields
  useEffect(() => {
    if (compression && namingConvention) {
      const validated = validateFields(namingConvention, compression);
      setNotMatchedType(!validated);
      if (!validated) {
        handlePackageName(namingConvention, !validated);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [namingConvention, compression]);

  return (
    <div className="data-packages-wrapper">
      <div
        className={
          isDraftEditMode ? "edit-wrapper fade-wrapper" : "edit-wrapper"
        }
      >
        <Panel
          onClose={handleClose}
          onOpen={handleOpen}
          open={isPanelOpen}
          width={446}
        >
          <LeftPanel dataflowSource={dataFlowdetail} resetForm={resetForm} />
        </Panel>
      </div>
      <Panel
        className={
          isPanelOpen ? classes.rightPanel : classes.rightPanelExtended
        }
        width="100%"
        hideButton
      >
        <main className="right-content">
          <Modal
            className="inactivePopup"
            open={addedPackage}
            // disableBackdropClick="true"
            onClose={() => handleAddedSuccess("", false, true)}
            title="Data Package created as inactive"
            message="Please add an active dataset, then activate the data package."
            buttonProps={[
              {
                label: "OK",
                variant: "primary",
                onClick: () => handleAddedSuccess("", false, true),
              },
            ]}
          />
          <Paper className="no-shadow">
            <Box className="top-content">
              {isShowAlertBox && SAVE_CHANGE_MODAL_FLAG && (
                <AlertBox
                  onClose={() => setShowAlertBox(false)}
                  submit={doCancel}
                  message="All unsaved changes will be lost."
                  title="Lose your work?"
                  dataflow
                />
              )}

              {/* Save Changes Modal */}
              {SAVE_CHANGE_MODAL_FLAG && (
                <SaveChangesModal
                  manualIsAnyChangeCheck={true}
                  manualIsAnyChangeFlag={isAnyChange}
                  shouldTriggerOnRedirect={
                    isDraftEditMode ? shouldTriggerRedirect : false
                  }
                  shouldCheckForChanges={isDraftEditMode}
                />
              )}
              <div
                className={
                  isDraftEditMode
                    ? "contentHeader fade-wrapper"
                    : "contentHeader"
                }
              >
                <Header
                  close={() => history.push("/dashboard")}
                  submit={submitPackage}
                  breadcrumbItems={breadCrumbItems}
                  headerTitle={
                    packageData.selectedPackage?.name ||
                    (packageData.selectedPackage?.nopackageconfig &&
                      "Data Package") ||
                    "Creating New Package"
                  }
                  icon={<DataPackageIcon className={classes.contentIcon} />}
                  saveBtnLabel={isVeevaOrTabularSod ? "Save Data Flow" : "Save"}
                  saveDisabled={!canUpdateDataFlow}
                  shouldDisplaySaveChangesModal={false}
                />
              </div>
            </Box>
          </Paper>

          <Box style={{ padding: 24, backgroundColor: "#f6f7fb" }}>
            <Paper className="add-package-box">
              {passwordFetchLoading && <Loader isInner={true} />}
              {showForm ? (
                <>
                  <div className="data-package-root">
                    <div className="data-setting-header">
                      <Typography className="b-font">
                        Data Package Settings
                      </Typography>
                      {configShow && (
                        <Checkbox
                          className="config-checkbox"
                          size="small"
                          label="Package Level Configuration"
                          checked={configShow}
                          onChange={showConfig}
                          disabled={
                            !(
                              !isPublishedFlow &&
                              (isDraftEditMode ||
                                isEmpty(packageData.selectedPackage))
                            ) || disabled
                          }
                        />
                      )}
                    </div>
                    {packageData.selectedPackage?.updt_tm && (
                      <span>
                        Last modified &nbsp;&nbsp;
                        {lastModifieddate}
                      </span>
                    )}
                    {!configShow && (
                      <div className="data-setting-body">
                        <DataPackageIcon className="package-icon" />
                        {!!fromCreate && (
                          <p>
                            Check “Package Level Configuration” to configure
                            package settings or click “Create dataset” to
                            continue and create dataset
                          </p>
                        )}
                        {!fromCreate && (
                          <p>
                            Check “Package Level Configuration” to configure
                            package settings
                          </p>
                        )}
                        <Checkbox
                          className="config-checkbox"
                          size="small"
                          label="Package Level Configuration"
                          checked={configShow}
                          onChange={showConfig}
                          disabled={
                            !(
                              !isPublishedFlow &&
                              (isDraftEditMode ||
                                isEmpty(packageData.selectedPackage))
                            ) || disabled
                          }
                        />
                      </div>
                    )}
                  </div>
                  {configShow && (
                    <div className="package-form">
                      <>
                        {!!showInput && (
                          <Select
                            error={notMatchedType}
                            label="Package Compression Type"
                            value={compression || ""}
                            size="small"
                            placeholder="Select type..."
                            onChange={(e) => {
                              setCompression(e.target.value);

                              // eslint-disable-next-line eqeqeq
                              if (e.target.value == "") {
                                setNotMatchedType(false);
                              }
                            }}
                            disabled={isVeevaOrTabularSod || !canUpdateDataFlow}
                            className="mb-20 package-type"
                          >
                            {Object.keys(compressionType.Compressiontype).map(
                              (i) => (
                                <MenuItem
                                  key={i}
                                  value={compressionType.Compressiontype[i]}
                                >
                                  {i}
                                </MenuItem>
                              )
                            )}
                          </Select>
                        )}
                        {!showInput && (
                          <div className="df-form-group">
                            <p className="df-form-label">
                              Package Compression Type
                            </p>
                            <p className="df-form-value">{compression}</p>
                          </div>
                        )}
                      </>
                      {!!showInput && (
                        <TextField
                          error={notMatchedType || isValidName}
                          className="mb-20"
                          label="Package Naming Convention"
                          inputProps={{ maxLength: 255 }}
                          placeholder=""
                          size="small"
                          fullWidth
                          value={namingConvention}
                          helperText={
                            notMatchedType || isValidName ? errorMessage : ""
                          }
                          onChange={(e) => {
                            setNotMatchedType(
                              !validateFields(e.target.value, compression)
                            );
                            handlePackageName(e.target.value);
                            setNamingConvention(e.target.value);
                          }}
                          disabled={!canUpdateDataFlow}
                        />
                      )}
                      {!showInput && (
                        <div className="df-form-group">
                          <p className="df-form-label">
                            Package Naming Convention
                          </p>
                          <p className="df-form-value">{namingConvention}</p>
                        </div>
                      )}
                      {!!showInput && (
                        <PasswordInput
                          size="small"
                          icon={false}
                          value={packagePassword}
                          label="Package Password (Optional)"
                          className="mb-20"
                          style={{ width: "70%" }}
                          onChange={(e) => setPackagePassword(e.target.value)}
                          disabled={!canUpdateDataFlow}
                        />
                      )}
                      {!showInput && (
                        <div className="df-form-group">
                          <p className="df-form-label">
                            Package Password (Optional)
                          </p>
                          <p className="df-form-value df-form-pass">
                            {packagePassword}
                          </p>
                        </div>
                      )}

                      {/* Folder Path will be not visible for S3 location */}
                      {!isS3Location && (
                        <>
                          {!!showInput && (
                            <TextField
                              className="mb-20"
                              label="sFTP Folder Path"
                              placeholder=""
                              error={folderPathValidation}
                              helperText={
                                folderPathValidation
                                  ? "Folder Path is required when Package Level Configuration is entered"
                                  : ""
                              }
                              defaultValue={sftpPath}
                              size="small"
                              fullWidth
                              onChange={(e) => {
                                setSftpPath(e.target.value);
                                setFolderPathValidation(false);
                              }}
                              disabled={!canUpdateDataFlow || isS3Location}
                            />
                          )}
                          {!showInput && (
                            <div className="df-form-group">
                              <p className="df-form-label">sFTP Folder Path</p>
                              <p className="df-form-value">{sftpPath}</p>
                            </div>
                          )}
                        </>
                      )}

                      {tabularSod && (
                        <>
                          {!!showInput && (
                            <div>
                              <Select
                                label="SOD View Type to Process"
                                value={sodValue}
                                size="small"
                                onChange={(e) => {
                                  setSodValue(
                                    e.target.value || sodValue || "Regular"
                                  );
                                }}
                                disabled={!canUpdateDataFlow}
                                className="mb-20 package-type"
                              >
                                {packageTypes.map((type, i) => (
                                  // eslint-disable-next-line react/no-array-index-key
                                  <MenuItem key={i} value={type.value}>
                                    {type.text}
                                  </MenuItem>
                                ))}
                              </Select>
                              <Tooltip
                                title="SOD View Type to Process"
                                subtitle="Files in the SOD package which match your selection will be processed. 
                                Please make sure that your selection and the generated SOD view type are in sync."
                                placement="left"
                                style={{ marginRight: 48 }}
                              >
                                <InfoIcon
                                  style={{ height: "2em", marginLeft: 10 }}
                                />
                              </Tooltip>
                            </div>
                          )}
                          {!showInput && (
                            <div className="df-form-group">
                              <p className="df-form-label">
                                SOD View Type to Process
                              </p>
                              <p className="df-form-value">{sodValue}</p>
                            </div>
                          )}
                        </>
                      )}
                    </div>
                  )}
                  {!isPublishedFlow && (
                    <div className="save-btn">
                      {!isPublishedFlow &&
                        (isDraftEditMode ||
                          isEmpty(packageData.selectedPackage)) && (
                          <>
                            <Button
                              variant="text"
                              disabled={btnLoading}
                              style={{ marginRight: 10 }}
                              onClick={() => {
                                handleCancelBtn();
                              }}
                            >
                              Cancel
                            </Button>
                            <Button
                              variant="primary"
                              style={{ marginRight: 10 }}
                              disabled={btnLoading}
                              onClick={async () => {
                                setBtnLoading(true);
                                await submitPackage();
                                setBtnLoading(false);
                              }}
                            >
                              {btnLoading && (
                                <>
                                  <CircularProgress
                                    className="modal-loader"
                                    size="14px"
                                  />
                                  &nbsp;
                                </>
                              )}
                              {fromCreate && !isVeevaOrTabularSod
                                ? "Create dataset"
                                : "Save"}
                            </Button>
                          </>
                        )}
                      {!isPublishedFlow &&
                        !isDraftEditMode &&
                        !isEmpty(packageData.selectedPackage) && (
                          <div className="edit-btn">
                            <Button
                              variant="secondary"
                              icon={Pencil}
                              style={{ marginRight: 10 }}
                              onClick={() => dispatch(setDraftEditMode(true))}
                            >
                              Edit
                            </Button>
                          </div>
                        )}
                    </div>
                  )}
                </>
              ) : (
                <>
                  <Box className="h-v-center flex-column add-btn-container">
                    <DataPackageIcon className="head-icon" />
                    <Typography variant="title1">
                      No Data Package or Datasets Added
                    </Typography>
                    <Button
                      variant="secondary"
                      icon={<PlusIcon />}
                      size="small"
                      disabled={!canUpdateDataFlow}
                      onClick={() => {
                        resetForm();
                        setShowForm(true);
                        dispatch(addPackageBtnAction());
                      }}
                    >
                      Add data package
                    </Button>
                  </Box>
                </>
              )}
            </Paper>
          </Box>
        </main>
      </Panel>
    </div>
  );
});

export default DataPackages;
