/* eslint-disable jsx-a11y/label-has-associated-control */
import React from "react";
import MenuItem from "apollo-react/components/MenuItem";
import Radio from "apollo-react/components/Radio";
import InfoIcon from "apollo-react-icons/Info";
import Tooltip from "apollo-react/components/Tooltip";

import { makeStyles } from "@mui/styles";

import {
  ReduxFormAutocompleteV2,
  ReduxFormRadioGroup,
  ReduxFormSelect,
  ReduxFormTextField,
  ReduxFormCheckbox,
  ReduxFormPassword,
} from "./FormComponents";

export const TooltipLabel = ({
  label,
  tooltip,
  placement = "right",
  showTooltipTitle = true,
  labelClass = "",
  maxWidth = 400,
}) => {
  const styles = {
    tooltip: {
      maxWidth,
    },
  };

  const useStyles = makeStyles(styles);
  const classes = useStyles();

  const Container = ({ children }) =>
    labelClass ? <p className={labelClass}>{children}</p> : <>{children}</>;

  return (
    <label>
      <Container>
        {label}
        <Tooltip
          title={showTooltipTitle ? label : ""}
          subtitle={!Array.isArray(tooltip) ? tooltip : ""}
          body={Array.isArray(tooltip) ? tooltip : null}
          placement={placement}
          style={{ marginRight: 48 }}
          classes={classes}
        >
          <InfoIcon
            fontSize="small"
            style={{
              position: "relative",
              top: 3,
              left: 3,
              width: 15,
              height: 15,
              color: "#595959",
            }}
          />
        </Tooltip>
      </Container>
    </label>
  );
};

export const CDITextBox = ({
  editMode,
  label,
  value,
  onChange,
  disabled = false,
  tooltip = "",
  name,
  maxLength = 255,
  required = false,
  visible = true,
  renderText = null,
  ...rest
}) => {
  try {
    if (!visible) return null;

    if (editMode)
      return (
        <ReduxFormTextField
          className="df-form-componets"
          fullWidth
          maxLength={maxLength}
          name={name}
          inputProps={{ maxLength }}
          onChange={onChange}
          disabled={disabled}
          required={required}
          label={
            tooltip ? <TooltipLabel label={label} tooltip={tooltip} /> : label
          }
          {...rest}
        />
      );

    return (
      <>
        <div className="df-form-group">
          <p className="df-form-label">{label}</p>
          <p className="df-form-value">
            {renderText ? renderText(value) : value}
          </p>
        </div>
      </>
    );
  } catch (error) {
    console.log(
      `Error occurred in TextBox name: ${name} value: ${value}`,
      error
    );
    return null;
  }
};

export const CDIDropDown = ({
  name,
  label,
  required = false,
  editMode,
  options,
  value,
  disabled,
  onChange,
  tooltip = "",
  visible = true,
  renderText = null,
  ...rest
}) => {
  try {
    if (!visible) return null;

    const listitems = options && options.length ? options : [];

    if (editMode)
      return (
        <ReduxFormSelect
          className="df-form-componets"
          key={`${name}-${value}`}
          name={name}
          label={
            tooltip ? <TooltipLabel label={label} tooltip={tooltip} /> : label
          }
          fullWidth
          canDeselect={false}
          default={value || ""}
          value={value || ""}
          required={required}
          input={{
            value,
            onChange,
            disabled,
          }}
          {...rest}
        >
          {listitems.map(({ text, value: id }) => (
            <MenuItem
              key={id}
              value={id}
              selected={value === id ? true : false}
            >
              {text}
            </MenuItem>
          ))}
        </ReduxFormSelect>
      );

    const viewText = () => {
      if (renderText && typeof renderText === "function")
        return renderText(value);

      const item = listitems.find((x) => x.value === value);
      if (item && item.text) return item.text;

      return "";
    };
    return (
      <div className="df-form-group">
        <p className="df-form-label">{label}</p>
        <p className="df-form-value">{viewText()}</p>
      </div>
    );
  } catch (error) {
    console.log(
      `Error occurred in DropDown name: ${name} value: ${value} options: ${options}`,
      error
    );
    return null;
  }
};

export const CDIAutoComplete = ({
  name,
  label,
  editMode,
  options,
  value,
  disabled,
  onChange,
  tooltip = "",
  visible = true,
  required = false,
  renderText,
  ...rest
}) => {
  try {
    if (!visible) return null;

    if (editMode)
      return (
        <ReduxFormAutocompleteV2
          key={value}
          name={name}
          autoSelect
          required={required}
          label={
            tooltip ? <TooltipLabel label={label} tooltip={tooltip} /> : label
          }
          source={options || []}
          id={label}
          matchFrom="any"
          input={{
            value,
            onChange,
            disabled,
          }}
          enableVirtualization
          className="autocomplete_field"
          singleSelect
          disableClearable={true}
          variant="search"
          fullWidth
          viewMode={true}
          {...rest}
        />
      );

    return (
      <div className="df-form-group">
        <p className="df-form-label">{label}</p>
        <p className="df-form-value">
          {renderText ? renderText(value) : value}
        </p>
      </div>
    );
  } catch (error) {
    console.log(
      `Error occurred in AutoComplete name: ${name} value: ${value} options: ${options}`,
      error
    );
    return null;
  }
};

export const CDIRadioGroup = ({
  name,
  label,
  editMode,
  options,
  value,
  disabled,
  onChange,
  tooltip = "",
  visible = true,
  required = false,
  renderText = undefined,
  ...rest
}) => {
  try {
    if (!visible) return null;

    if (editMode)
      return (
        <ReduxFormRadioGroup
          name={name}
          label={
            tooltip ? <TooltipLabel label={label} tooltip={tooltip} /> : label
          }
          onChange={onChange}
          disabled={disabled}
          required={required}
          className="dataset-data-flow-type"
          {...rest}
        >
          {(options || [])?.map((x) => (
            <Radio className="radio-option" value={x.value} label={x.text} />
          ))}
        </ReduxFormRadioGroup>
      );

    return (
      <div className="df-form-group">
        <p className="df-form-label">{label}</p>
        <p className="df-form-value">
          {renderText
            ? renderText(value)
            : (options || [])?.find((x) => x.value === value)?.text}
        </p>
      </div>
    );
  } catch (error) {
    console.log(
      `Error occurred in RadioGroup name: ${name} value: ${value} options: ${options}`,
      error
    );
    return null;
  }
};

export const CDICheckBox = ({
  disabled = false,
  name,
  onChange,
  size = "small",
  tooltipLabel = "",
  tooltipText = "",
  value,
  visible = true,
  placement = "right",
  shouldTooltip = true,
  labelText = "",
  highlightError = false,
  showErrorMessage = true,
  ...rest
}) => {
  try {
    if (!visible) return null;

    if (shouldTooltip)
      return (
        <>
          <ReduxFormCheckbox
            fullWidth
            name={name}
            id={name}
            disabled={disabled}
            value={value || ""}
            onChange={onChange}
            size={size}
            highlightError={highlightError}
            showErrorMessage={showErrorMessage}
            label={
              // eslint-disable-next-line react/jsx-wrap-multilines
              <TooltipLabel
                label={tooltipLabel}
                tooltip={tooltipText}
                placement={placement}
              />
            }
            {...rest}
          />
        </>
      );

    return (
      <>
        <ReduxFormCheckbox
          fullWidth
          name={name}
          id={name}
          disabled={disabled}
          value={value || ""}
          onChange={onChange}
          size={size}
          label={labelText}
          highlightError={highlightError}
          showErrorMessage={showErrorMessage}
          {...rest}
        />
      </>
    );
  } catch (error) {
    console.log(
      `Error occured in CDICheckBox name: ${name} value: ${value}`,
      error
    );
    return null;
  }
};

export const CDIPassword = ({
  editMode,
  label,
  value,
  onChange,
  disabled = false,
  tooltip = "",
  name,
  maxLength = 255,
  required = false,
  visible = true,
  renderText = null,
  ...rest
}) => {
  try {
    if (!visible) return null;

    if (editMode)
      return (
        <ReduxFormPassword
          fullWidth
          maxLength={maxLength}
          name={name}
          inputProps={{ maxLength }}
          onChange={onChange}
          disabled={disabled}
          required={required}
          label={
            tooltip ? <TooltipLabel label={label} tooltip={tooltip} /> : label
          }
          {...rest}
        />
      );

    return (
      <>
        <div className="df-form-group">
          <p className="df-form-label">{label}</p>
          <p className="df-form-value">
            {"*".repeat((renderText ? renderText(value) : value).length || 0)}
          </p>
        </div>
      </>
    );
  } catch (error) {
    console.log(
      `Error occurred in TextBox name: ${name} value: ${value}`,
      error
    );
    return null;
  }
};

export default {
  CDITextBox,
  CDICheckBox,
  CDIRadioGroup,
  CDIAutoComplete,
  CDIDropDown,
  TooltipLabel,
  CDIPassword,
};
