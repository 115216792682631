/* eslint-disable camelcase */
/* eslint-disable no-case-declarations */
/* eslint-disable eqeqeq */
import { produce } from "immer";

import moment from "moment";
import {
  GET_VENDORS_DATA,
  GET_LOCATIONS_DATA,
  FETCH_LOCATION_SUCCESS,
  FETCH_LOCATION_FAILURE,
  FETCH_VENDOR_SUCCESS,
  FETCH_VENDOR_FAILURE,
  UPDATE_FORM_FIELDS,
  GET_SERVICE_OWNERS,
  FETCH_SERVICE_OWNERS_SUCCESS,
  FETCH_SERVICE_OWNERS_FAILURE,
  RESET_DF_FORMDATA,
  HIDE_ERROR_MSG,
  UPDATE_SELECTED_LOCATION,
  FETCH_DATAFLOW_DETAIL_FAILURE,
  FETCH_DATAFLOW_DETAIL_SUCCESS,
  ADD_DATAFLOW_SUCCESS,
  SAVE_DATAFLOW_LOCAL_DETAIL,
  UPDATE_DS,
  TOGGLE_DF_BTN,
  SET_VERSION_FREEZED,
  RESET_DATAFLOW_DETAILS,
  SET_PUBLISH_FLOW,
  SET_DRAFT_EDIT_MODE,
  GET_DATAFLOW_DETAIL,
  TRIGGER_DATAFLOW_DETAILS_PULL,
  SET_FROM_CREATE_DRAFT,
} from "../../constants";

export const initialState = {
  loading: false,
  createTriggered: false,
  error: null,
  selectedLocation: {},
  serviceOwner: [],
  serviceOwners: [],
  locations: [],
  vendors: [],
  userName: "",
  password: "",
  connLink: "",
  description: "",
  dataflowType: "test",
  dataStructure: "Tabular",
  selectedVendor: {},
  dataFlowdetail: {},
  testProdLock: false,
  prodLock: false,
  testLock: false,
  dsTestProdLock: false,
  dsProdLock: false,
  dsTestLock: false,
  edc_study_nm: "",
  edc_study_env: "",
  edc_provider: "",
  run_interval: 0,
  page_size: 0,
  cdd_flag: false,
  odm_flag: true,
  isDatasetCreation: true,
  updated: false,
  disableCreateBtn: false,
  versionFreezed: false,
  isPublishedFlow: true,
  isDraftEditMode: false,
  fromCreateDraft: false,
  pageLoaders: {
    vendorFetchLoading: false,
    locationFetchLoading: false,
    serviceOwnerLoading: false,
    dataflowFetchLoading: false,
  },
};

const DataFlowReducer = (state = initialState, action) =>
  produce(state, (newState) => {
    switch (action.type) {
      case GET_VENDORS_DATA:
        newState.loading = true;
        newState.pageLoaders = {
          ...state.pageLoaders,
          vendorFetchLoading: true,
        };
        break;
      case GET_LOCATIONS_DATA:
        newState.loading = true;
        newState.pageLoaders = {
          ...state.pageLoaders,
          locationFetchLoading: true,
        };
        break;
      case FETCH_LOCATION_SUCCESS:
        newState.loading = false;
        newState.pageLoaders = {
          ...state.pageLoaders,
          locationFetchLoading: false,
        };
        newState.locations = action.locations;
        break;

      case UPDATE_DS:
        newState.isDatasetCreation = action.status;
        if (action.status) {
          newState.dsTestProdLock = false;
          newState.dsProdLock = false;
          newState.dsTestLock = false;
        } else {
          newState.dsTestProdLock = state.testProdLock;
          newState.dsProdLock = state.prodLock;
          newState.dsTestLock = state.testLock;
        }
        break;

      case ADD_DATAFLOW_SUCCESS:
        newState.selectedDataFlow = action.dataflow;
        newState.updated = true;
        break;

      case FETCH_LOCATION_FAILURE:
        newState.loading = false;
        newState.pageLoaders = {
          ...state.pageLoaders,
          locationFetchLoading: false,
        };
        break;
      case FETCH_VENDOR_SUCCESS:
        newState.loading = false;
        newState.pageLoaders = {
          ...state.pageLoaders,
          vendorFetchLoading: false,
        };
        newState.vendors = action.vendors;
        break;

      case FETCH_VENDOR_FAILURE:
        newState.loading = false;
        newState.pageLoaders = {
          ...state.pageLoaders,
          vendorFetchLoading: false,
        };
        break;
      case UPDATE_SELECTED_LOCATION:
        newState.selectedLocation = action.location;
        newState.loading = false;
        break;
      case UPDATE_FORM_FIELDS:
        newState.loading = false;
        if (action.field === "description" || action.field === "dataflowType") {
          newState[action.field] = action.value.target.value;
        } else if (action.field === "vendor") {
          const vendorId = action.value;
          newState.selectedVendor = state.vendors?.records?.find(
            (v) => vendorId === v.vend_id
          );
        }
        break;
      case GET_SERVICE_OWNERS:
        newState.loading = true;
        newState.pageLoaders = {
          ...state.pageLoaders,
          serviceOwnerLoading: true,
        };
        break;
      case SET_VERSION_FREEZED:
        newState.versionFreezed = action.freezed ? true : false;
        break;
      case RESET_DF_FORMDATA:
        newState.formData = {};
        break;
      case FETCH_SERVICE_OWNERS_FAILURE:
        newState.loading = false;
        newState.pageLoaders = {
          ...state.pageLoaders,
          serviceOwnerLoading: false,
        };
        break;
      case FETCH_SERVICE_OWNERS_SUCCESS:
        newState.loading = false;
        newState.pageLoaders = {
          ...state.pageLoaders,
          serviceOwnerLoading: false,
        };
        newState.serviceOwners = action.serviceOwners;
        break;
      case HIDE_ERROR_MSG:
        newState.error = action.message;
        break;
      case SAVE_DATAFLOW_LOCAL_DETAIL:
        // eslint-disable-next-line no-case-declarations
        const { details } = action;
        newState.dataFlowdetail = details;
        newState.loading = false;
        break;
      case FETCH_DATAFLOW_DETAIL_FAILURE:
        newState.loading = false;
        newState.pageLoaders = {
          ...state.pageLoaders,
          dataflowFetchLoading: false,
        };
        newState.error = action.message;
        break;
      case TOGGLE_DF_BTN:
        newState.disableCreateBtn = action.disabled;
        break;
      case GET_DATAFLOW_DETAIL:
        newState.loading = true;
        newState.pageLoaders = {
          ...state.pageLoaders,
          dataflowFetchLoading: true,
        };
        break;
      case FETCH_DATAFLOW_DETAIL_SUCCESS:
        newState.loading = false;
        newState.pageLoaders = {
          ...state.pageLoaders,
          dataflowFetchLoading: false,
        };
        // eslint-disable-next-line no-case-declarations
        const { dataflowDetail } = action;

        // eslint-disable-next-line no-case-declarations
        const {
          description = "",
          exptfstprddt,
          loctyp,
          name,
          srclocid,
          type,
          username,
          vendorid,
          vendorname,
          testflag,
          locationname,
          isSync,
          serviceowner,
          edc_study_nm,
          edc_study_env,
          edc_provider,
          run_interval,
          page_size,
          cdd_flag,
          odm_flag,
          has_rcds_flag,
          audit_adundum_page,
          data_pages,
          rcds_run_interval,
          standardizeMetadata,
          dataflowSchedule,
        } = dataflowDetail;

        newState.testLock = testflag === 1 && isSync === "Y";
        newState.prodLock = testflag === 0 && isSync === "Y";
        newState.testProdLock = isSync === "Y";
        newState.dsTestLock = testflag === 1 && isSync === "Y";
        newState.dsProdLock = testflag === 0 && isSync === "Y";
        newState.dsTestProdLock = isSync === "Y";
        newState.edc_study_nm = edc_study_nm;
        newState.edc_study_env = edc_study_env;
        newState.edc_provider = edc_provider;
        newState.run_interval = run_interval;
        newState.page_size = page_size;
        newState.cdd_flag = cdd_flag;
        newState.odm_flag = odm_flag;

        // eslint-disable-next-line no-case-declarations
        const formData = {};
        formData.description = description;
        formData.firstFileDate = moment(exptfstprddt).isValid()
          ? moment(exptfstprddt)
          : null;
        formData.locationType = loctyp;
        formData.name = name;
        formData.dataflowType = testflag === 1 ? "test" : "production";
        formData.locations = [{ value: srclocid, label: locationname }];
        formData.dataStructure = type;
        formData.vendors = [vendorid];
        formData.userName = username;
        formData.vendorname = vendorname;
        formData.serviceOwner = serviceowner ? serviceowner.split(",") : [];
        formData.edc_study_nm = edc_study_nm;
        formData.edc_study_env = edc_study_env;
        formData.edc_provider = edc_provider;
        formData.run_interval = run_interval;
        formData.page_size = page_size;
        formData.cdd_flag = cdd_flag ? true : false;
        formData.odm_flag = odm_flag ? true : false;
        formData.rcds_flag = has_rcds_flag ? true : false;
        formData.rcds_data_pages = data_pages ? true : false;
        formData.rcds_audit_addendum = audit_adundum_page ? true : false;
        formData.rcds_run_interval = rcds_run_interval;
        formData.standardizeMetadata = standardizeMetadata ? true : false;
        formData.dataflowSchedule = dataflowSchedule;
        dataflowDetail.standardizeMetadata = standardizeMetadata ? true : false;
        dataflowDetail.dataflowSchedule = dataflowSchedule;
        newState.dataFlowdetail = action.dataflowDetail;
        newState.formData = formData;
        break;
      case SET_PUBLISH_FLOW:
        newState.isPublishedFlow = action.state;
        break;
      case SET_DRAFT_EDIT_MODE:
        newState.isDraftEditMode = action.state;
        break;
      case SET_FROM_CREATE_DRAFT:
        newState.fromCreateDraft = action.state;
        break;
      case TRIGGER_DATAFLOW_DETAILS_PULL:
        newState.dataFlowdetail = {};
        newState.isPublishedFlow = false;
        break;
      case RESET_DATAFLOW_DETAILS:
        newState.selectedLocation = {};
        newState.serviceOwner = [];
        newState.serviceOwners = [];
        newState.locations = [];
        newState.vendors = [];
        newState.userName = "";
        newState.password = "";
        newState.connLink = "";
        newState.description = "";
        newState.selectedVendor = {};
        newState.dataFlowdetail = {};
        newState.isDraftEditMode = false;
        newState.testProdLock = false;
        newState.prodLock = false;
        newState.testLock = false;
        newState.dsTestProdLock = false;
        newState.dsProdLock = false;
        newState.dsTestLock = false;
        newState.edc_study_nm = "";
        newState.edc_study_env = "";
        newState.edc_provider = "";
        newState.run_interval = 0;
        newState.page_size = 20;
        newState.cdd_flag = false;
        newState.odm_flag = true;
        newState.formData = { odm_flag: true };

        break;
      default:
        newState.loading = false;
        break;
    }
  });

export default DataFlowReducer;
